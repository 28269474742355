import React from 'react';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaChartBar } from 'react-icons/fa';
import ServiceTemplate from '../../components/service/ServiceTemplate';

const AnalyticsService = ({ data }) => {
  const seoData = {
    title: 'アクセス解析・改善提案｜奈良のホームページ制作なら｜インヴォルブ',
    description:
      'Google AnalyticsやSearch Consoleのデータを分析し、サイトの課題を明確化。効果的な改善提案を行い、集客力とコンバージョン率を向上させます。',
    pageUrl: 'https://www.involve-in.jp/service/analytics',
    ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'サービス一覧', url: 'https://www.involve-in.jp/service/' },
    { name: 'アクセス解析・改善提案', url: seoData.pageUrl },
  ];

  const works = data.allContentfulCaseStudy.nodes;

  return (
    <>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <ServiceTemplate
        title="アクセス解析・改善提案"
        subtitle="データから最適な改善策を提案"
        icon={FaChartBar}
        description="Google AnalyticsやSearch Consoleのデータを分析し、サイトの課題を明確化。効果的な改善提案を行い、集客力とコンバージョン率を向上させます。"
        seo={seoData}
        breadcrumbs={breadcrumbList}
        benefits={[
          {
            title: 'データを基にした改善',
            description: '感覚ではなく、数値に基づいた戦略を提案。',
          },
          {
            title: 'ユーザー行動を分析',
            description: '滞在時間・離脱ポイント・CV率などを詳細に調査。',
          },
          {
            title: 'SEOとマーケティング視点で最適化',
            description: '検索エンジンと訪問者の両方に配慮した改善策を提供。',
          },
        ]}
        flow={[
          {
            title: 'データ取得',
            duration: '1日',
            description:
              'Google AnalyticsやSearch Consoleのデータを取得・整理。',
          },
          {
            title: 'ユーザー行動の分析',
            duration: '3日〜',
            description: '流入経路、滞在時間、離脱ポイントなどを分析。',
          },
          {
            title: '改善策の提案',
            duration: '1週間',
            description: '課題に応じたSEO対策やUI/UX改善策をレポート化。',
          },
          {
            title: '施策の実施支援',
            duration: null,
            description: '必要に応じて、施策実施のサポートも可能。',
          },
        ]}
        preparation={[
          {
            title: '解析ツールの設定',
            description: 'Google AnalyticsやSearch Consoleを事前に設定。',
          },
          {
            title: '改善したいポイントの整理',
            description: 'どの指標を重視するか、事前に決めておくとスムーズ。',
          },
          {
            title: '競合サイトの情報',
            description: '参考になる競合サイトがあれば、分析に役立てます。',
          },
          {
            title: 'アクセスデータの提供',
            description:
              '事前にアクセスデータを共有いただくと、詳細な分析が可能。',
          },
        ]}
        works={works.map((work) => ({
          title: work.title,
          image:
            work.image && work.image.length > 0
              ? getImage(work.image[0].gatsbyImageData)
              : null,
        }))}
        price="¥50,000〜"
        cta="解析を依頼する"
      />
    </>
  );
};

export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

export default AnalyticsService;
